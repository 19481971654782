import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../../components/layout/layout";
import SEO from "../../components/seo";
import HeaderLight from "../../components/header/headerLight/headerLight";

import "./style.scss";
import Button from "../../components/button/button";

const MentionsLegales = (location) => {

  return (
    <>
      <Helmet>
        <body className="mentions-légales" />
      </Helmet>
      <Layout>
        <SEO title="Mentions Légales" description="Nous vous proposons de l’information sur L’Auxiliaire et sur L’Auxiliaire vie, leurs activités, leurs offres de services en matière d’assurance, de bâtiment et de travaux publics."/>
        <HeaderLight
          title={
            <>Mentions <br/> <strong>légales</strong>
            </>
          }
          location={location}
          crumbLabel="Mentions Légales"
        />

        <div className="mentions-legales">

          <section className="mentions-legales__section-intro">
            <p>Le site internet www.auxiliaire.fr (le site) est exploité par la Mutuelle d'Assurance des Professionnels du Bâtiment et des Travaux Publics (L’Auxiliaire), sise 20 rue Garibaldi 69413 Lyon, dont le SIREN est le n°775 649 056.<br/><br/>Le Directeur de publication du Site est M. Olivier BEDEAU et le Webmaster est Mme Sophie SPINDLLER.<br/><br/>Le site est hébergé sur les propres serveurs de L’Auxiliaire.<br/><br/>Les présentes Conditions Générales ont pour objet de prévoir l’ensemble des conditions d’accès et d’utilisation du site, les parties sécurisées de l’Espace Client et de l'Espace Courtier répondant également à des conditions d’utilisation.<br/><br/>L’accès et l’utilisation du site www.auxiliaire.fr impliquent l’acceptation tacite et sans réserve des présentes Conditions Générales par l’Utilisateur, sous leur dernière version en ligne.
            </p>
          </section>

          <section className="mentions-legales__section-menu">
            <h3>Accès rapides</h3>
            <a href="#description_du_service" className="underline-hover">1. Description du service</a>
            <a href="#acces_et_disponibilite" className="underline-hover">2. Accès et disponibilité</a>
            <a href="#responsabilite" className="underline-hover">3. Responsabilité</a>
            <a href="#propriete_intellectuelle" className="underline-hover">4. Propriété intellectuelle</a>
            <a href="#confidentialite_et_donnees_personelles" className="underline-hover">5. Confidentialité et données personnelles</a>
            <a href="#finalite_des_donnees_collectees" className="underline-hover">6. Finalité des données collectées</a>
            <a href="#reclamations_et_organisme_de_controle" className="underline-hover">7. Reclamations et organisme de contrôle</a>
            <a href="#divers" className="underline-hover">8. Divers</a>
            <a href="#mentions_legales" className="underline-hover">9. Mentions légales</a>
          </section>

          <div className="mentions-legales__content">

            <section id="description_du_service">
              <h2>1. Description du service</h2>
              <p>
                L'Auxiliaire propose sur le site www.auxiliaire.fr de l’information sur L’Auxiliaire et sur L’Auxiliaire vie, leurs activités, leurs offres de services en matière d’assurance dans le secteur des BTP, ainsi que de l’information pratique sur ledit secteur et sur celui de l’assurance BTP.<br/><br/>Les Utilisateurs peuvent également demander par l’intermédiaire du site www.auxiliaire.fr la communication d’informations spécifiques en remplissant les formulaires accessibles en ligne, ainsi qu’à être contactés par téléphone par un Conseiller, pour toutes demandes d’information.<br/><br/>Les clients-sociétaires de L’Auxiliaire ont par ailleurs accès sur le site www.auxiliaire.fr à un Espace Client sur lequel ils peuvent accéder à différents services, notamment consulter des informations relatives à leur situation ou demander des documents (par exemple, des attestations d’assurance).<br/><br/>Les Courtiers Partenaires de L'Auxiliaire ont accès à un Espace Courtier sur lequel ils peuvent accéder à différents services relatifs à leur activité vis-à-vis de notre Mutuelle.
                <br/>L’accès à ces zones est soumis à l’acceptation des Conditions d’Utilisation.
              </p>
            </section>

            <section id="acces_et_disponibilite">
              <h2>2. Accès et disponibilité</h2>
              <p>
                L’accès au Site www.auxiliaire.fr est ouvert à toute personne souhaitant s’informer sur les offres de produits et de services de L’Auxiliaire.<br/><br/>L’accès au Site www.auxiliaire.fr est assuré de façon continue et permanente, sauf cas de force majeure ou d’évènements hors du contrôle de L’Auxiliaire, et sous réserve des éventuelles pannes et interventions de maintenance nécessaires au bon fonctionnement du Site.<br/><br/>Cependant, en raison de la nature même de l’Internet, L’Auxiliaire ne peut garantir un fonctionnement du Site www.auxiliaire.fr 24 heures sur 24 et 7 jours sur 7.<br/><br/>L’Utilisateur s’engage à accéder au Site www.auxiliaire.fr avec du matériel récent, sans virus et avec un navigateur à jour.
              </p>
            </section>

            <section id="responsabilite">
              <h2>3. Responsabilité</h2>
              <p>
                3.1 L’Auxiliaire s'efforce d'offrir aux Utilisateurs l'information la plus fiable et qualitative qui soit.<br/><br/>L’Auxiliaire ne garantit pas cependant l'exactitude et l'exhaustivité de l'information diffusée sur le Site www.auxiliaire.fr.<br/><br/>L’Auxiliaire ne saurait donc être tenu pour responsable des dommages directs ou indirects subis par les Utilisateurs ou par des tiers qui trouveraient leur source dans l'information diffusée sur le Site, et de manière plus générale, dans la consultation et l'utilisation du Site.<br/><br/>3.2 L’Auxiliaire n'exerce par ailleurs aucune surveillance sur les sites reliés au Site par le biais de liens hypertexte, et décline toute responsabilité liée à ces sites et aux contenus qui s'y trouvent.
              </p>
            </section>

            <section id="propriete_intellectuelle">
              <h2>4. Propriété intellectuelle</h2>
              <p>
                4.1 Toutes les données de quelque nature qu'elles soient, et notamment les textes, graphismes, logos, icônes, images, clips audio ou vidéo, marques, logiciels, informations ou illustration figurant sur le site www.auxiliaire.fr sont nécessairement protégées par le droit d'auteur, le droit des marques et tous autres droits de propriété intellectuelle, et appartiennent à L’Auxiliaire, ou à des tiers ayant autorisé L’Auxiliaire à les exploiter.
                <br/>L’Auxiliaire, en sa qualité d'Editeur du Site, consent à l'Utilisateur un droit d'usage privé, non collectif et non exclusif sur ledit contenu.<br/><br/>L'Utilisateur s'engage dans ces conditions à ne pas reproduire, résumer, modifier, altérer ou rediffuser, sans autorisation expresse préalable de l'Editeur, les textes, graphismes, logos, icônes, images, clips audio ou vidéo, marques, logiciels, informations ou illustration figurant sur le site www.auxiliaire.fr pour un usage autre que strictement privé, ce qui exclut toute représentation à des fins professionnelles ou de rediffusion en nombre. De même, l'Utilisateur s'engage à ne pas recopier tout ou partie du site sur un autre site ou un réseau interne d'entreprise.<br/><br/>4.2 L’ensemble des données accessibles et des contenus publiés sur le Site www.auxiliaire.fr, ainsi que leurs modalités de consultation, constituent la Base de Données du Site Internet de L’Auxiliaire.
                <br/>Cette Base de Données est protégée par les dispositions du Code de la Propriété Intellectuelle relatives au droit d’auteur, ainsi que par les dispositions de la Directive Européenne du 11 mars 1996.<br/><br/>Dans ces conditions, l’Utilisateur s’engage à utiliser ces données dans le strict cadre de la consultation du site Internet www.auxiliaire.fr, et s’interdit notamment de reproduire, traduire, adapter, arranger, transformer, communiquer, représenter et distribuer, de façon permanente ou provisoire, par tout moyen et sous quelque forme que ce soit tout ou partie des données contenues dans ces bases, autrement que dans les cas visés sous l’alinéa 4.1 du présent article.<br/><br/>4.3 Toute création de lien hypertexte d'un site Internet vers le Site devra faire l'objet d'une autorisation expresse de L’Auxiliaire.
              </p>
            </section>

            <section id="confidentialite_et_donnees_personelles">
              <h2>5. Confidentialité et données personnelles</h2>
              <p>
                Conformément aux dispositions de la Loi n° 78-17 relative à l’informatique, aux fichiers et aux libertés du 6 janvier 1978 modifiée et complétée par la Règlement Européen sur la Protection des Données applicable depuis le 25 mai 2018, le site a fait l’objet d’une déclaration à la CNIL (Commission Nationale Informatique et Liberté) sous le n°855897.<br/><br/>Les Utilisateurs du Site ayant fourni des informations personnelles par l’intermédiaire du Site disposent donc, suivant l’Article 34 de la Loi du 6 janvier 1978, d’un droit d’accès aux données qui les concernent, ainsi que d’un droit de modification et de suppression de celles-ci.<br/><br/>Il leur suffit, pour exercer ce droit, d’adresser un courrier postal ou un e-mail aux coordonnées suivantes :
                <br/>L'Auxiliaire
                <br/>À l'attention du Délégué à la Protection des Données
                <br/>20 rue Garibaldi BP 6402
                <br/>69413 Lyon Cedex 06
                <br/>Tél. 04 72 74 52 52
                <br/>Email : contactdpd@auxiliaire.fr<br/><br/>Les données personnelles relatives aux Utilisateurs, recueillies par l'intermédiaire des formulaires, et toutes informations futures, ne sont destinées qu'à un usage exclusif de L’Auxiliaire, dans le cadre de la mise en œuvre des services offerts aux Utilisateurs sur le Site, et ne font l’objet d’aucune communication ou cession à des tiers.<br/><br/>Les données personnelles peuvent néanmoins être traitées par des partenaires ou des sous-traitants exclusivement afin de réaliser les finalités de la présente politique. En vertu de l’application du Règlement Européen sur la Protection des Données (RGPD), L’Auxiliaire est tenue de communiquer aux tiers dont les données personnelles pourraient être atteintes en cas d’attaque.
              </p>

              <Button
                className="bloc-image__btn"
                text={"En savoir plus"}
                onClick={() => (window.location.href = "/protection-donnees-personnelles")}
              />

            </section>

            <section id="finalite_des_donnees_collectees">
              <h2>6. Finalité des données collectées</h2>
              <p>
                6.1 En remplissant des formulaires, vous nous transmettez des informations qui pourront être utilisées par L’Auxiliaire, la SGAM btp ou leurs partenaires pour nous permettre de répondre à votre demande.<br/><br/>Ces informations pourront également être utilisées à des fins commerciales ou d’information pour vous proposer des produits ou des services. Cette utilisation à des fins commerciales ou d’information ne sera faite que si vous nous en donnez l’autorisation, en cochant la case prévue à cet effet dans le formulaire.<br/><br/>6.2 Précision relative aux candidatures à des offres d’emploi ou candidatures spontanées : nous vous informons que les données vous concernant sont informatisées et traitées de façon confidentielle. Elles sont conservées pour une durée maximum de 24 mois.
              </p>
            </section>

            <section id="reclamations_et_organisme_de_controle">
              <h2>7. Reclamations et organisme de contrôle</h2>
              <p>
                Entreprise agréée et contrôlée par l’Autorité de contrôle prudentiel et de résolution (ACPR) - 4 place de Budapest, CS92459, 75436 Paris Cedex 09.<br/><br/>En cas de réclamation : vous pouvez nous contacter ici ou par courrier postal à : L'Auxiliaire - Service Relations Clients - 20 rue Garibaldi - BP 6402 - 69413 Lyon cedex 06.<br/><br/>En cas de réponse non satisfaisante à la réclamation : Médiation de l'Assurance, Pôle CSCA, TSA50110, 75441 Paris Cedex 09
                ou <a href="https://www.mediation-assurance.org">https://www.mediation-assurance.org</a>
              </p>
            </section>

            <section id="divers">
              <h2>8. Divers</h2>
              <p>
                8.1 Dans l’hypothèse où l’une des dispositions des présentes Conditions serait considérée comme nulle en vertu d’une disposition légale ou réglementaire, présente ou future, ou d’une décision de justice revêtue de l’autorité de la chose jugée et émanant d’une juridiction ou d’un organisme compétent, cette disposition du contrat serait considérée comme étant non écrite, toutes les autres dispositions des présentes Conditions Générales d’Utilisation conservant force obligatoire entre les Parties.
                <br/>8.2 L’Auxiliaire se réserve le droit de modifier unilatéralement les termes des présentes Conditions. Les nouvelles clauses régiront pour l’avenir toutes les relations entre les parties, et seule la nouvelle version fera foi entre celles-ci.
                <br/>8.3 Les présentes Conditions Générales d’Utilisation sont régies par la loi française tant pour les règles de fond que pour les règles de forme. En cas de litige, les parties conviennent de rechercher une solution amiable avant de saisir la juridiction compétente. Pour tout litige avec un professionnel, les parties conviennent de saisir la juridiction compétente dans le ressort de la Cour d’Appel de Lyon.
              </p>
            </section>

            <section id="mentions_legales">
              <h2>9. Mentions légales</h2>
              <p>
                  L'Auxiliaire - mutuelle d’assurance des professionnels du bâtiment et des travaux publics - société d’assurance mutuelle à cotisations variables régie par le code des assurances et exonérée de plein droit de la TVA - Siège : 20 rue Garibaldi - BP 6402 - 69413 Lyon Cedex 06 - SIREN 775649056 - code APE 6512Z  - ADEME FR325390_03IUSC - <a target={"_blank"} href={"https://www.auxiliaire.fr"}>www.auxiliaire.fr</a> - <a href={"tel:0472745252"}>04 72 74 52 52</a> - <a href={"mailto:auxiliaire@auxiliaire.fr"}>auxiliaire@auxiliaire.fr</a>
                  <br/><br/>
              </p>
              <p>
                  L’Auxiliaire Vie - mutuelle d’assurance sur la vie des professionnels du bâtiment et des travaux publics - société d’assurance mutuelle à cotisations fixes régie par le code des assurances et exonérée de plein droit de la TVA - Siège : 20 rue Garibaldi - BP 6402 - 69413 Lyon Cedex 06 - RCS Lyon D 324 774 298 - code APE 6511Z - ADEME FR325391_03KAVV - <a target={"_blank"} href={"https://www.auxiliaire.fr"}>www.auxiliaire.fr</a> - <a href={"tel:0472745252"}>04 72 74 52 52</a> - <a href={"mailto:auxiliaire@auxiliaire.fr"}>auxiliaire@auxiliaire.fr</a>
              </p>
            </section>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default MentionsLegales;
